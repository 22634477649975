import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faShoppingCart, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../context/CartContext';
import { Offcanvas, Button, Form } from 'react-bootstrap';
import emptyCart from '../../assets/img/cart/shopping-bag.png';
import { useNavigate } from 'react-router-dom';
import { areaData, SubArea, subAreaData } from './area';


type CartFloatingButtonProps = {
  onToggleOffcanvas: (isOpen: boolean) => void; // Prop type definition
};

export default function CartFloatingButton({ onToggleOffcanvas }: CartFloatingButtonProps) {
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [placement, setPlacement] = useState<'bottom' | 'end'>('bottom');
  const [view, setView] = useState<'cart' | 'address'>('cart'); // Toggle between cart and address view
  const { cart, isCartPopoverOpen, incrementQuantity, decrementQuantity } = useCart();
  const [selectedArea, setSelectedArea] = useState('');
  const [subareas, setSubareas] = useState<SubArea[]>([]);
  const navigate = useNavigate();


  const handleAreaChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAreaId = event.target.value;
    setSelectedArea(selectedAreaId);
    if (selectedAreaId && subAreaData[selectedAreaId]) {
      setSubareas(subAreaData[selectedAreaId]);
    } else {
      setSubareas([]);
    }
  };

  useEffect(() => {
    const updatePlacement = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth > 768) {
        setPlacement('end'); // Desktop opens from right
      } else {
        setPlacement('bottom'); // Mobile opens from bottom
      }
    };

    updatePlacement();
    window.addEventListener('resize', updatePlacement);

    return () => window.removeEventListener('resize', updatePlacement);
  }, []);

  useEffect(() => {
    if (isCartPopoverOpen) {
      setShowOffcanvas(true);
    }
  }, [isCartPopoverOpen]);

  const toggleOffcanvas = () => {
    setShowOffcanvas(!showOffcanvas);
  };

  
  const navigateToProducts = () => {
    setShowOffcanvas(false);
    onToggleOffcanvas(false); // Pass the state to the parent
    navigate('/product-list');
  };

  const calculateSubtotal = () => {
    return cart.reduce((total, item) => total + item.price * item.quantity, 0);
  };

  const deliveryCharges = 'Free';
  const totalPay = calculateSubtotal();

  return (
    <>
      <button
        className={`floating-cart-button ${cart.length > 0 ? 'highlighted' : ''}`}
        onClick={toggleOffcanvas}
      >
        <FontAwesomeIcon icon={faShoppingCart} style={{ fontSize: '20px' }} />
        {cart.length > 0 && <span className="cart-count">{cart.length}</span>}
      </button>

      <Offcanvas
        show={showOffcanvas}
        onHide={toggleOffcanvas}
        placement={placement}
        className="cart-offcanvas"
      >
        <Offcanvas.Header closeButton>
          {view === 'address' ? (
            <>
              <button
                className="back-button"
                onClick={() => setView('cart')}
              >
                <FontAwesomeIcon style={{ color: 'white', marginRight: '20px' }} icon={faArrowLeft} />
              </button>
              <Offcanvas.Title>Enter Address</Offcanvas.Title>
            </>
          ) : (
            <Offcanvas.Title>Your Cart</Offcanvas.Title>
          )}
        </Offcanvas.Header>


        <Offcanvas.Body>
          {view === 'cart' ? (
            <>
              {cart.length === 0 ? (
                <div className="empty-cart-section">
                  <img src={emptyCart} alt="Empty Cart" className="empty-cart" />
                  <h4 className="empty-cart-title">Your Cart is Empty</h4>
                  <Button
                    variant="primary"
                    className="browse-products-btn"
                    onClick={navigateToProducts}
                  >
                    Browse Products
                  </Button>
                </div>
              ) : (
                <>
                  <div className="cart-content">
                    {cart.map((item) => (
                      <div key={item.id} className="cart-item">
                        <img src={item.image} alt={item.title} className="cart-item-image" />
                        <div className="cart-item-info">
                          <h4>{item.title}</h4>
                          <p>Rs {item.price}</p>
                        </div>
                        <div className="quantity-controls">
                          <button onClick={() => decrementQuantity(item.id)} className="quantity-btn">
                            <FontAwesomeIcon icon={faMinus} />
                          </button>
                          <span className="quantity">{item.quantity}</span>
                          <button onClick={() => incrementQuantity(item.id)} className="quantity-btn">
                            <FontAwesomeIcon icon={faPlus} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="bill-summary">
                    <h4>Bill Summary</h4>

                    <p>
                      <span className="label">Subtotal:</span>
                      <span className="value">Rs {calculateSubtotal()}</span>
                    </p>

                    <p>
                      <span className="label">Delivery Charges:</span>
                      <span className="value" style={{ color: '#2ab939' }}> {deliveryCharges}</span>
                    </p>

                    <div className="dotted-line"></div>

                    <p>
                      <span className="label"><strong>Total Pay:</strong></span>
                      <span className="value" style={{ fontWeight: 600 }}>Rs {totalPay}</span>
                    </p>

                    <Button
                      style={{ background: '#2ab939', border: 'none', height: '40px' }}
                      variant="primary"
                      className="next-btn"
                      onClick={() => setView('address')}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="address-form">
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control type="text" placeholder="Enter your phone number" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>City</Form.Label>
                  <Form.Control type="text" value="Gurugram" placeholder="Enter your city" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter your address" />
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Area</Form.Label>
                  <Form.Select value={selectedArea} onChange={handleAreaChange}>
                    {areaData.map((area) => (
                      <option key={area.area_id} value={area.area_id}>
                        {area.area_name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <Form.Label>Subarea</Form.Label>
                  <Form.Select>
                    {subareas.length > 0 ? (
                      subareas.map((subarea) => (
                        <option key={subarea.sub_area_id} value={subarea.sub_area_id}>
                          {subarea.subarea_name}
                        </option>
                      ))
                    ) : (
                      <option value="">Select a subarea</option>
                    )}
                    {/* Add more subareas here */}
                  </Form.Select>
                </Form.Group>

                <Button
                  style={{
                    background: '#2ab939',
                    border: 'none',
                    height: '40px',
                    width: '100%',
                  }}
                  variant="success"
                  type="submit"
                  className="submit-btn"
                >
                  Place Order
                </Button>
              </Form>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}