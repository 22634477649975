import React, { useEffect, useState } from 'react';
import { Navigation, Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperType } from 'swiper';
import product_data from '../../data/product_data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeaf, faShieldAlt, faFlask, faPrescriptionBottleAlt, faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../context/CartContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ProductDetailsArea() {
    const { productId } = useParams<{ productId: string }>();
    const product = product_data.find((item) => item.id === parseInt(productId || '', 10));
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [quantity, setQuantity] = useState(1);
    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const [searchParams] = useSearchParams();
    const [startDate, setStartDate] = useState('');
    const { clearCart, addToCart, openCartPopover } = useCart();
    const [selectedOption, setSelectedOption] = useState("daily");
    const [selectedTab, setSelectedTab] = useState<'trial' | 'subscribe' | 'once'>('subscribe');
    const [selectedTrialOption, setSelectedTrialOption] = useState("offer1");
    const [packagingType, setPackagingType] = useState("pouch");
    const [currentProduct, setCurrentProduct] = useState<{ id: number; tab: 'trial' | 'subscribe' | 'once' } | null>(null);


    // const handleAddToCart = () => {
    //     if (product) {
    //         clearCart();
    //         addToCart({
    //             id: product.id,
    //             title: product.title,
    //             price: product.price,
    //             quantity: quantity, // Use quantity here
    //             image: product.image
    //         });

    //         toast.success(`${product.title} has been added to your cart!`, {
    //             position: "top-right",
    //             autoClose: 3000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //         });

    //         if (selectedTab === 'trial') {
    //             openCartPopover();
    //         }
    //     }
    // };

    const handleAddToCart = () => {
        if(product) {
        const newProduct = { id: product?.id, tab: selectedTab };

        // Check if the product is different or if the tab is "trial" or "subscribe"
        const isDifferentProduct = currentProduct?.id !== newProduct.id;
        const isDifferentTab = currentProduct?.tab !== newProduct.tab;
        const shouldClearCart = (selectedTab === 'trial' || selectedTab === 'subscribe') && (isDifferentProduct || isDifferentTab);

        if (shouldClearCart) {
            clearCart(); // Clear cart only for different products in trial or subscribe tabs
        }

        // Add the product to the cart
        addToCart({
            id: product.id,
            title: product.title,
            price: product.price,
            quantity: 1, 
            image: product.image
        });

              if (selectedTab === 'trial') {
                openCartPopover();
            }

        // Update the current product and tab state
        setCurrentProduct(newProduct);

        // Show success toast message
        toast.success(`${product.title} has been added to your cart!`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
    };


    const handleDateChange = (event: any) => {
        setStartDate(event.target.value);
    };

    const increment = () => {
        setQuantity(quantity + 1);
    };

    const decrement = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 767);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        const formattedDate = tomorrow.toISOString().split('T')[0];
        setStartDate(formattedDate);
    }, []);

    useEffect(() => {
        const tab = searchParams.get('tab');
        const size = searchParams.get('size');

        if (tab) {
            setSelectedTab(tab as 'trial' | 'subscribe' | 'once'); // Set the tab based on the query param
        }

        if (size && product) {
            const selectedSizeProduct = product_data.find(p => p.title === product.title && p.size.includes(size));
            if (selectedSizeProduct) {
                // Replace the product with the specific size if found
                window.history.replaceState({}, '', `/product-details/${selectedSizeProduct.id}`);
            }
        }
    }, [searchParams, product]);

    return (
        <>
            <section className="product-details-section overflow-hidden section-padding" style={{ padding: '20px 0' }}>
                <div className="container">
                    <div className="row g-xl-4 g-3 justify-content-center">
                        <div className="col-lg-12" style={{ padding: isMobile ? '0' : '0 80px' }}>
                            <div className="row g-4 mb-60">
                                <div className="col-xl-5 col-lg-6">
                                    <div className="shop-details-wrap">
                                        <Swiper
                                            loop={true}
                                            spaceBetween={10}
                                            navigation={{
                                                nextEl: ".swiper-button-next",
                                                prevEl: ".swiper-button-prev",
                                            }}
                                            thumbs={{ swiper: thumbsSwiper }}
                                            modules={[Navigation, Thumbs]}

                                            className="swiper mySwiper2">

                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-bigthumb">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-bigthumb">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-bigthumb">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-bigthumb">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-bigthumb">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>

                                            <div className="swiper-button-next"></div>
                                            <div className="swiper-button-prev"></div>
                                        </Swiper>
                                        <Swiper
                                            onSwiper={(swiper: SwiperType) => setThumbsSwiper(swiper)}
                                            loop={true}
                                            spaceBetween={10}
                                            slidesPerView={3}
                                            freeMode={true}
                                            watchSlidesProgress={true}
                                            breakpoints={{
                                                480: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 10,
                                                },
                                                991: {
                                                    slidesPerView: 4,
                                                    spaceBetween: 20,
                                                },
                                                1199: {
                                                    slidesPerView: 5,
                                                    spaceBetween: 24,
                                                },
                                            }}

                                            className="swiper mySwiper">

                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            {/* repeat */}
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>
                                            <SwiperSlide className="swiper-slide">
                                                <div className="shop-details-samll d-center">
                                                    <img src={product?.image} alt="img" />
                                                </div>
                                            </SwiperSlide>

                                        </Swiper>
                                    </div>
                                </div>
                                <div className="col-xl-7 col-lg-6">
                                    <div className="product-infowrap">
                                        <h3 className="title" style={{ marginBottom: '5px' }}>
                                            {product?.title}
                                        </h3>
                                        {selectedTab !== 'trial' && (
                                            <>
                                                <p className="product-volume" style={{ marginBottom: '10px' }}>{product?.size}</p>
                                                <h3 className="prices">
                                                    <span style={{ fontWeight: 600 }}>Rs {product?.price}</span>
                                                </h3>
                                            </>
                                        )}
                                        <div className="tabs-container">
                                            <div className="tabs">
                                                <button
                                                    className={`tab ${selectedTab === "once" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("once")}
                                                >
                                                    Buy Once
                                                </button>
                                                <button
                                                    className={`tab ${selectedTab === "trial" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("trial")}
                                                >
                                                    Start Trial
                                                </button>
                                                <button
                                                    className={`tab ${selectedTab === "subscribe" ? "active" : ""}`}
                                                    onClick={() => setSelectedTab("subscribe")}
                                                >
                                                    Subscribe
                                                </button>
                                            </div>

                                            <div className="tab-content">
                                                {selectedTab === "trial" && (
                                                    <>
                                                        <div className="offer-card">
                                                            <label
                                                                className={`radio-card ${selectedTrialOption === "offer1" ? "selected" : ""}`}
                                                            >
                                                                <div className="offer-badge">Special Offer</div>
                                                                <input
                                                                    type="radio"
                                                                    name="trial"
                                                                    value="offer1"
                                                                    onChange={() => setSelectedTrialOption("offer1")}
                                                                />
                                                                <div className="offer-content">
                                                                    <h4 className="offer-title">Order for 4 Days</h4>
                                                                    <p className="offer-description">Get the next 3 days <span className="highlight-text">FREE!</span></p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="offer-terms">
                                                            <span className="terms-title">Terms and Conditions:</span>
                                                            <ul className="terms-list">
                                                                <li>Offer is applicable on max 1 unit per delivery.</li>
                                                                <li>Only one offer can be availed per customer.</li>
                                                                <li>Applicable on any one type of milk.</li>
                                                                <li>No cancellations or refunds once the offer is activated.</li>
                                                            </ul>
                                                        </div>
                                                    </>
                                                )}

                                                {selectedTab === "subscribe" && (
                                                    <div>
                                                        <div className="radio-buttons-group">
                                                            <label
                                                                className={`radio-button ${selectedOption === "daily" ? "selected" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="subscribe"
                                                                    value="daily"
                                                                    onChange={() => setSelectedOption("daily")}
                                                                />
                                                                Daily
                                                            </label>

                                                            <label
                                                                className={`radio-button ${selectedOption === "weekly" ? "selected" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="subscribe"
                                                                    value="weekly"
                                                                    onChange={() => setSelectedOption("weekly")}
                                                                />
                                                                Weekly
                                                            </label>

                                                            <label
                                                                className={`radio-button ${selectedOption === "monthly" ? "selected" : ""
                                                                    }`}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    name="subscribe"
                                                                    value="monthly"
                                                                    onChange={() => setSelectedOption("monthly")}
                                                                />
                                                                Monthly
                                                            </label>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="frequency-date-row">

                                            {/* Start Date Calendar */}
                                            <div className="input-group">
                                                <label htmlFor="start-date" className="input-label">Start Date</label>
                                                <input
                                                    type="date"
                                                    id="start-date"
                                                    value={startDate}
                                                    onChange={handleDateChange}
                                                    className="date-input"
                                                />
                                            </div>
                                            {selectedTab !== 'trial' && <div className="quantity-wrapper">
                                                <label htmlFor="quantity" className="input-label">Quantity</label>
                                                <div className="quantity-controls">
                                                    <button className="decrement" onClick={decrement}>
                                                        <FontAwesomeIcon icon={faMinus} />
                                                    </button>
                                                    <input
                                                        type="text"
                                                        id="quantity"
                                                        value={quantity}
                                                        readOnly
                                                        className="quantity-input"
                                                    />
                                                    <button className="increment" onClick={increment}>
                                                        <FontAwesomeIcon icon={faPlus} />
                                                    </button>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                        {selectedTab === 'trial' && <div>
                                            <label className="input-label">Packaging Type</label>
                                            <div className="radio-card-group" style={{ marginTop: '10px' }}>
                                                <label
                                                    className={`radio-button ${packagingType === "pouch" ? "selected" : ""
                                                        }`}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="packagingType"
                                                        value="pouch"
                                                        onChange={() => setPackagingType("pouch")}
                                                    />
                                                    Pouch - Rs {product?.price} / 500 ml
                                                </label>

                                                <label
                                                    className={`radio-button ${packagingType === "glassBottle" ? "selected" : ""
                                                        }`}
                                                >
                                                    <input
                                                        type="radio"
                                                        name="packagingType"
                                                        value="glassBottle"
                                                        onChange={() => setPackagingType("glassBottle")}
                                                    />
                                                    Glass Bottle - Rs {(product?.price ?? 0) + 4} / 500 ml
                                                </label>
                                            </div>
                                        </div>
                                        }
                                        <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                            <a
                                                href="#"
                                                className="cmn-btn d-inline-flex"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    handleAddToCart();
                                                }}
                                            >
                                                Add to cart
                                            </a>
                                        </div>
                                        <ToastContainer />
                                        <div>
                                            <p>{product?.subDesc}</p>

                                            <div className="product-features-row d-flex flex-wrap mt-3 mb-4">
                                                <div className="feature-item d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faShieldAlt} className="feature-icon" />
                                                    <span className="feature-text"> No Adulteration</span>
                                                </div>
                                                <div className="feature-item d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faFlask} className="feature-icon" />
                                                    <span className="feature-text">No Hormones</span>
                                                </div>
                                                <div className="feature-item d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faLeaf} className="feature-icon" />
                                                    <span className="feature-text">No Pesticides</span>
                                                </div>
                                                <div className="feature-item d-flex align-items-center">
                                                    <FontAwesomeIcon icon={faPrescriptionBottleAlt} className="feature-icon" />
                                                    <span className="feature-text">No Antibiotics</span>
                                                </div>
                                            </div>

                                            <h4 style={{ fontSize: '16px', fontWeight: 600, marginTop: '30px' }}>Why Choose Our {product?.title}?</h4>
                                            <ul className="custom-list">
                                                {product?.whyChoose?.map((item, index) => (
                                                    <li key={index}>
                                                        <b>{item.title}:</b> {item.description}
                                                    </li>
                                                ))}
                                            </ul>

                                            <h4 style={{ fontSize: '16px', fontWeight: 600, marginTop: '30px' }}>Key Benefits</h4>
                                            <ul className="custom-list">
                                                {product?.keyBenefits?.map((item, index) => (
                                                    <li key={index}>
                                                        <b>{item.title}:</b> {item.description}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
